<template>
	<div class="web-content">
		<div class="user-nav">
		  <el-row>
			<el-col :span="20">
				<el-form :model="queryParams" ref="queryForm" :inline="true">
				  <el-form-item prop="classId">
					<el-select
					  v-model="queryParams.classId"
					  placeholder="请选择班级"
					  style="width: 150px"
					>
					  <el-option
						v-for="(item, index) in constData.classList"
						:key="index"
						:label="item.deptName"
						:value="item.deptId"
					  ></el-option>
					</el-select>
				  </el-form-item>
				  <el-form-item prop="userNameOrLoginName">
					<el-input
					  v-model="queryParams.userNameOrLoginName"
					  placeholder="学生姓名/学籍号"
					  clearable
					/>
				  </el-form-item>
				  <el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="getMainList"
					  >搜索</el-button
					>
				  </el-form-item>
				</el-form>
			</el-col>
		  </el-row>
		  <!-- <el-row>
			  <el-col :span="4" :offset="20" style="text-align: right">
			  	<el-button plain size="small" type="primary" @click="openImport">导入考核成绩</el-button>
			  </el-col>
		  </el-row> -->
		</div>
		
		<el-table style="width: 100%"
		  border
		  :header-cell-style="tableHeaderColor"
		  :cell-style="cellStyleFun"
		  :data="mainList.list">
			<el-table-column type="index" label="序号" width="50"></el-table-column>
			<el-table-column prop="userName" label="学生姓名"></el-table-column>
			<el-table-column prop="loginName" label="学籍号"></el-table-column>
			<el-table-column prop="className" label="班级"></el-table-column>
			<el-table-column prop="midExamScore1" label="七年级下学期成绩"></el-table-column>
			<el-table-column prop="midExamScore2" label="八年级上学期成绩"></el-table-column>
			<el-table-column prop="midExamScore3" label="八年级下学期成绩"></el-table-column>
			<el-table-column prop="midExamScore4" label="九年级上学期成绩"></el-table-column>
			<el-table-column prop="totalMidExamScore" label="中考过程性成绩"></el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button size="mini" type="text" @click="stuDetail(scope.row)">查看详情</el-button>
				</template>
			</el-table-column>
		</el-table>
		
		<el-dialog title="体育技能考核成绩" :visible.sync="dialog.stuDetail" width="650px" center>
			<p>学生姓名：{{ stuDetailData.nm }}</p>
			<p>学籍号：{{ stuDetailData.loginNm }}</p>
			<table class="stu-score-detail">
				<tr>
					<th class="term">学期</th>
					<th>项目1</th>
					<th>项目1成绩</th>
					<th>项目2</th>
					<th>项目2成绩</th>
					<th class="final-score">中考过程性成绩</th>
				</tr>
				<tr v-for="(item, idx) in stuDetailData.list" :key="idx">
					<td>{{ item.convertTermName }}</td>
					<td>{{ item.projectOne }}</td>
					<td>{{ item.projectOneScore }}</td>
					<td>{{ item.projectTwo }}</td>
					<td>{{ item.projectTwoScore }}</td>
					<td>{{ item.midExamScore }}</td>
				</tr>
			</table>
		</el-dialog>
		
	</div>
</template>

<script>
import { getClass } from "@/api/public/search"
import { 
	midPhySportExam,
	midPhySportExamTplDl,
	midPhySportExamImport,
	midPhySportExamStuDetail
} from "@/api/teacher/quality/health"
export default {
	data() {
		return {
			queryParams: {
				classId: '',
				userNameOrLoginName: ''
			},
			constData: {
				classList: []
			},
			mainList: {
				list: [],
				titleList: [],
				loading: false
			},
			dialog: {
				stuDetail: false
			},
			stuDetailData: {
				nm: '',
				loginNm: '',
				list: []
			}
		}
	},
	methods: {
		getFirst() {
			this.getClassList()
		},
		// 获取班级
		getClassList() {
		  getClass({}).then((res) => {
			  let r = res.data
		    this.constData.classList = r
		    this.queryParams.classId = r[0].deptId
			this.getMainList()
		  })
		},
		// 主列表
		getMainList() {
			midPhySportExam(this.queryParams).then(res => {
				this.mainList.list = res.data.list
			})
		},
		// 学生详情
		stuDetail(row) {
			this.stuDetailData.nm = row.userName
			this.stuDetailData.loginNm = row.loginName
			midPhySportExamStuDetail({
				userId: row.userId
			}).then(res => {
				this.stuDetailData.list = res.data
				this.dialog.stuDetail = true
			})
		},
		// 表头
		tableHeaderColor({ row, column, rowIndex, columnIndex }) {
		  if (rowIndex === 0) {
		    return "background-color: #F6F7FA;color: #616266;font-weight: blod;text-align:center";
		  }
		},
		// 单元格
		cellStyleFun() {
		  return "text-align:center";
		}
	}
}
</script>

<style scoped lang="scss">
.stu-score-detail {
	width: 100%;
	border: 1px solid #CCC;
	border-collapse: collapse;
	th, td {
		padding: 10px 0;
		width: 16%;
		border: 1px solid #CCC;
		text-align: center;
	}
	.final-score {
		width: 34%;
	}
	.term {
		width: 18%;
	}
}
</style>