<template>
	<div class="web-content">
		<div class="user-nav">
		  <el-row>
			<el-form :model="queryParams" ref="queryForm" :inline="true">
			  
			  <el-form-item prop="deptId">
				<el-select
				  v-model="queryParams.deptId"
				  placeholder="请选择班级"
				  style="width: 150px"
				  @change="changeCls"
				>
				  <el-option
					v-for="(item, index) in constData.classList"
					:key="index"
					:label="item.deptName"
					:value="item.deptId"
				  ></el-option>
				</el-select>
			  </el-form-item>
			  <el-form-item prop="userNameOrLoginName">
				<el-input
				  v-model="queryParams.userNameOrLoginName"
				  placeholder="学生姓名/学籍号"
				  clearable
				/>
			  </el-form-item>
			  <el-form-item>
				<el-button type="primary" icon="el-icon-search" @click="getMainList"
				  >搜索</el-button
				>
			  </el-form-item>
			</el-form>
		  </el-row>
		</div>
		
		<el-table style="width: 100%"
		  border
		  :header-cell-style="tableHeaderColor"
		  :cell-style="cellStyleFun"
		  :data="mainList.list">
			<el-table-column type="index" label="序号" width="50"></el-table-column>
			<el-table-column v-for="(item, idx) in mainList.titleList" :key="idx" :prop="item.code" :label="item.name"></el-table-column>
		</el-table>
	</div>
</template>

<script>
import { getClass } from "@/api/public/search"
import { midPhyHealthTest } from "@/api/teacher/quality/health"
export default {
	data() {
		return {
			queryParams: {
				deptId: '',
				deptName: '',
				userNameOrLoginName: ''
			},
			constData: {
				classList: []
			},
			mainList: {
				list: [],
				titleList: [],
				loading: false
			}
		}
	},
	methods: {
		getFirst() {
			this.getClassList()
		},
		// 获取班级
		getClassList() {
		  getClass({}).then((res) => {
			  let r = res.data
		    this.constData.classList = r
		    this.queryParams.deptId = r[0].deptId
			this.queryParams.deptName = r[0].deptName
			this.getMainList()
		  })
		},
		// 变更班级
		changeCls(v) {
			this.queryParams.deptId = v
			this.queryParams.deptName = this.constData.classList.filter(v => v.deptId == this.queryParams.deptId)[0].deptName
		},
		// 主列表
		getMainList() {
			midPhyHealthTest(this.queryParams).then(res => {
				this.mainList.list = res.data.tableData
				this.mainList.titleList = res.data.titles
			})
		},
		// 表头
		tableHeaderColor({ row, column, rowIndex, columnIndex }) {
		  if (rowIndex === 0) {
		    return "background-color: #F6F7FA;color: #616266;font-weight: blod;text-align:center";
		  }
		},
		// 单元格
		cellStyleFun() {
		  return "text-align:center";
		}
	}
}
</script>

<style>
</style>