<template>
	<div>
		<el-tabs v-model="activeName" type="card">
		  <el-tab-pane label="中考体育过程性考核成绩" name="0">
		    <ProcessScore ref="processScore"></ProcessScore>
		  </el-tab-pane>
		  <el-tab-pane label="体育出勤率" name="1">
			<Attendance ref="attendance"></Attendance>
		  </el-tab-pane>
		  <el-tab-pane label="学生体质健康测试" name="2">
		    <HealthTest ref="healthTest"></HealthTest>
		  </el-tab-pane>
		  <el-tab-pane label="体质技能考核" name="3">
		    <SportExam ref="sportExam"></SportExam>
		  </el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import ProcessScore from './component/processScore.vue'
import Attendance from './component/attendance.vue'
import HealthTest from './component/healthTest.vue'
import SportExam from './component/sportExam.vue'
export default {
	components: {
		ProcessScore,
		Attendance,
		HealthTest,
		SportExam
	},
	data() {
		return {
			activeName: ''
		}
	},
	created() {
		this.activeName = '0'
	},
	watch: {
		'activeName'(nv) {
			if (nv == 0) {
				this.$refs['processScore'].getClassList()
			} else if (nv == 1) {
				this.$refs['attendance'].getFirst()
			} else if (nv == 2) {
				this.$refs['healthTest'].getFirst()
			} else if (nv == 3) {
				this.$refs['sportExam'].getFirst()
			}
		}
	}
}
</script>

<style>
</style>